h1, h2, h3, h4, h5, h6 {
  margin-top: 0 !important;
  margin-bottom: 0;
  //letter-spacing: 1px;
}

a{
  text-decoration: none;
}

ion-header {
  ion-toolbar {
    ion-button {
      --padding-start: 0;
      --padding-end: 0;
      height: 36px;
      width: 38px;
      --border-radius: 6px;
      --background: #ffffff;
      --color: var(--ion-color-primary);
    }

    ion-title{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      text-align: center;
      padding-left: 45px;
      padding-right: 45px;
    }
  }
}

ion-tab-bar {
  position: relative;
  bottom: 15px;
  width: 92%;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

  ion-tab-button {
    flex-grow: 1;
    --color: #B2B2B2;
    --color-slected: var(--ion-color-primary);

    ion-icon {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }
}

.d-flex {
  display: flex !important;
}

.icon-bg-1 {
  background: #e6f0fe;
}

.icon-bg-2 {
  background: #c9f7f5;
}

.icon-bg-3 {
  background: #fff4de;
}

.icon-bg-4 {
  background: #ffe2e5;
}

.custom-input {
  margin-bottom: 20px;
  --inner-padding-end: 2px;
  --padding-start: 16px;
  --background: #ffffff;

  //box-shadow: rgba(17, 17, 26, 0.05) 0 1px 0, rgba(17, 17, 26, 0.1) 0 0 5px;
  box-shadow: rgba(3, 51, 122, 0.08) 0 1px 4px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: 6px;

  ion-input {
    --padding-top: 16px;
    --padding-bottom: 16px;
  }
}

.custom-btn {
  margin: 0 2px;
  --border-radius: 6px;
  height: 53px;
  --box-shadow: none;
  font-size: 17px;
  text-transform: capitalize;

  &.ion-activated {
    box-shadow: none !important;
  }
}



.select-list {
  position: relative;



  ion-select {
    background: #ffffff;
    margin-bottom: 22px;
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 6px;
    padding: 16px 12px;
    box-shadow: rgba(3, 51, 122, 0.08) 0 1px 4px;
  }

  ion-icon {
    right: 12px;
    position: absolute;
    z-index: 12;
    top: 19px;
    color: var(--ion-color-medium);
  }
}

.default-btn{
  background-color: var(--ion-color-primary);
  color: #fff !important;
  padding: 8px 30px;
  border-radius: 3px;
}

.select-dropdown {
  .action-sheet-group:first-of-type {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding-top: 10px;
    background: #ffffff;
  }

  .select-interface-option {
    --button-color: var(--ion-color-medium-shade);
    --button-color-hover: var(--ion-color-primary);
    font-size: 15px;
  }

  .action-sheet-selected {
    color: var(--ion-color-primary);
  }

  .action-sheet-group:nth-of-type(2) {
    background: #ffffff;
    .action-sheet-button {
      font-size: 18px;
      font-weight: 500;
    }
  }
}
