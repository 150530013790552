.test-container {
  ion-select::part(icon) {
    display: none;
  }

  .test-list {
    position: relative;

    ion-select {
      background: #ffffff;
      margin-bottom: 22px;
      padding: 14px 12px;
      border-radius: 6px;
      box-shadow: rgba(3, 51, 122, 0.08) 0 1px 4px;
    }

    ion-icon {
      right: 12px;
      position: absolute;
      z-index: 12;
      top: 17px;
    }
  }

  .collapse-icon{
    background: #ffffff;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    margin-right: 14px;
  }

  .test-body{
    flex-grow: 1;

    .test-info{
      flex-direction: column;

      &:nth-of-type(2){
        text-align: right;
      }

      h6{
        font-size: 15px;
        margin-bottom: 4px;
        color: var(--ion-color-primary);
      }

      span{
        font-size: 14px;
        color: var(--ion-color-medium);
      }

      ion-chip{
        font-size: 10px;
        margin: 0;
        padding: 5px 10px;
        height: 24px;
        //font-weight: 500;
      }
    }
  }
}
