ion-header.profile-header {
  ion-toolbar {
    ion-title {
      padding-left: 16px;
    }
  }
}

.profile-container {
  .user-info {
    margin-bottom: 30px;
    margin-top: 10px;

    .profile-image {
      padding: 7px;
      border-radius: 6px;
      background: #ffffff;
      width: 100px !important;
      height: 100px !important;
      margin: 0 auto 30px;

      ion-avatar {
        --border-radius: 6px !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    h1 {
      margin-bottom: 8px;
    }

    h3 {
      color: var(--ion-color-medium);
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;

      ion-icon {
        color: var(--ion-color-primary);
        margin-right: 8px;
      }
    }
  }

  .user-setting-list {
    padding-left: 4px;
    padding-right: 4px;

    ion-item {
      --background: #ffffff;
      margin-bottom: 10px;
      border-radius: 6px;
      box-shadow: rgba(3, 51, 122, 0.08) 0px 1px 4px;

      ion-label {
        --color: #7c7c7c;

        h2 {
          font-weight: 500;
        }
      }

      ion-icon {
        --color: #7c7c7c;
      }
    }
  }
}