/* Style the buttons that are used to open and close the accordion panel */

.accordion-header {
  background-color: #ffffff;
  cursor: pointer;
  padding: 18px;
  margin-bottom: 10px;
  transition: 0.4s;
  border-radius: 6px;
  box-shadow: rgba(3, 51, 122, 0.08) 0 1px 4px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */


.accordion-header:hover {
  background-color: rgba(3, 51, 122, 0.08);
}

/* Style the accordion panel. Note: hidden by default */

.accordion-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;

  .test-table-wrapper {
    padding: 18px;
    margin-bottom: 10px;
    background-color: #ffffff;
    box-shadow: rgba(3, 51, 122, 0.08) 0 1px 4px;
    border-radius: 7px;

    .default-btn {
      font-size: 12px;
      padding: 8px 10px;
    }

    .test-table {
      width: 100%;
      margin-bottom: 15px;

      thead {
        tr:first-of-type {
          border-bottom: 1px solid #cccccc;
        }
      }

      tbody tr td:nth-of-type(odd) {
        font-weight: bold;
      }

      //tbody tr:nth-of-type(1) {
      //  td {
      //    padding-top: 15px !important;
      //  }
      //}

      th, td {
        text-align: left;
        padding: 5px;
        font-size: 13px;
        vertical-align: baseline;
      }
    }
  }
}
