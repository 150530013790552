.sign-up-container{
  justify-content: flex-start;

  .logo{
    margin-top: 10px;
  }

  .sign-up-link{
    position: unset;
    bottom: unset;

    p{
      font-size: 14px;
    }
  }
}