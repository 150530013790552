.about-us {
  padding: 30px 0;
  .about-detail {
    padding: 0 20px;

    ion-img {
      margin: 0 auto 30px;
      width: 110px;
    }

    h6{
      margin-bottom: 20px!important;
    }
  }
  p{
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: var(--ion-color-medium);
  }
}