.dashboard-header {
  ion-toolbar {
    margin-bottom: -1px;
    padding: 0 16px;

    ion-button{
      margin-top: 16px;
    }
  }
}

//.dashboard{
//  background: var(--ion-color-primary);
//}

.dashboard-container {
  flex-direction: column;
  background: var(--ion-color-primary);

  height: 100%;

  .dashboard-top {
    padding: 25px 0;
    background: var(--ion-color-primary);

    h3 {
      color: #ffffff;
      font-weight: bold;
      font-size: 20px;

      span{
        font-weight: normal;
      }
    }
  }

  .dashboard-bottom {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    padding: 0 16px;
    padding-top: 36px;
    background: #F9F9F9;
    flex-grow: 1;

    h1 {
      margin-bottom: 30px;
      font-weight: 600;
      color: var(--ion-color-primary);
    }

    .box {
      background-color: #ffffff;
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      border-radius: 15px;
      padding: 20px;
      margin-bottom: 25px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .box-icon {
        padding: 10px;
        border-radius: 7px;
        width: 49px;
        height: 45px;

        ion-icon {
          font-size: 25px;
          color:var(--ion-color-primary);
        }
      }

      .box-detail {
        h3 {
          margin-bottom: 3px !important;
        }

        h4 {
          color: var(--ion-color-medium);
          font-size: 16px;
        }
      }
    }
  }
}