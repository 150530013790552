.login-container {
  height: 100%;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .logo {
    margin-bottom: 20px;

    ion-img {
      width: 150px;
    }
  }

  .login-info {
    width: 100%;


    ion-list {
      padding-bottom: 0;
    }

    .custom-btn {
      margin-bottom: 20px;
    }

    a {
      font-weight: 500;
      display: block;
      text-align: center;
      margin-bottom: 45px;
    }
  }

  .sign-up-link {
    position: absolute;
    bottom: 5px;

    p {
      margin: 0;
      color: var(--ion-color-medium);
      font-weight: 500;
      font-size: 14px;

      a {
        font-weight: 500;
        margin-left: 5px;
      }
    }
  }
}