.branches-container {

  .lab-branch {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: rgba(3, 51, 122, 0.1) 0 0 5px 0, rgba(3, 51, 122, 0.1) 0 0 1px 0;
    position: relative;
    margin-bottom: 22px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .call-icon {
      position: absolute;
      right: 16px;
      height: 35px;
      width: 35px;
      border-radius: 50%;
      background: var(--ion-color-primary);
      color: #ffffff !important;

      &:hover, &:active, &:focus {
        background: var(--ion-color-primary);

        ion-icon {
          color: #ffffff !important;
        }
      }

      ion-icon {
        font-size: 18px;
        color: #ffffff !important;
      }
    }

    h2 {
      font-size: 19px;
      margin-bottom: 16px;
      color: var(--ion-color-primary);
    }

    h4 > ion-icon, p > ion-icon {
      margin-right: 8px;
      color: var(--ion-color-primary);
    }

    h4 {
      font-size: 16px;
      margin-bottom: 8px;
    }

    p {
      font-size: 15px;
      margin: 0;
      color: var(--ion-color-medium-shade);
      position: relative;
      padding-left: 25px;

      ion-icon {
        position: absolute;
        left: 0;
      }
    }
  }
}