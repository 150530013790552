.account-container {
  .custom-list {
    --background: #ffffff;
    border-radius: 15px;

    ion-item {
      --background: #ffffff;
      margin-bottom: 1px;

      &:last-of-type{
        margin-bottom:0;
      }

      &:first-of-type{
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

      &:last-of-type{
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
      }

      ion-icon {
        color: var(--ion-color-light-shade);
        font-size: 18px;
      }

      ion-label {
        h2{
          font-weight: 500;
          font-size: 15px;
        }

        p {
          color: var(--ion-color-medium-shade);
          font-weight: 400;
          font-size: 14px;
        }
      }

      ion-datetime{
        font-size: 14px;
        color: var(--ion-color-medium);
      }

      ion-toggle {
        padding-right: 8px;
      }
    }
  }
}