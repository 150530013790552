.booking-container{
  ion-select::part(icon) {
    display: none;
  }

  .booking-info{
    ion-list{
      padding-bottom: 0;

      ion-select{
        color: var(--ion-color-medium);
      }
    }
  }

  .test-table-wrapper {
    padding: 18px;
    margin-bottom: 22px;
    background-color: #ffffff;
    box-shadow: rgba(3, 51, 122, 0.08) 0 1px 4px;
    border-radius: 7px;

    .test-table {
      width: 100%;

      thead {
        tr:first-of-type {
          border-bottom: 1px solid #cccccc;
        }
      }

      tbody tr:nth-of-type(1) {
        td {
          padding-top: 15px !important;
        }
      }

      thead tr:nth-of-type(1) {
        th {
          padding-bottom: 15px !important;
        }
      }

      tbody tr td:last-of-type{
        text-align: center;
        vertical-align: middle;

        ion-icon{
          font-size: 16px;
        }
      }

      tbody tr td:first-of-type{
        width: 60%;
      }

      tbody tr:nth-last-of-type(2){
        border-bottom: 1px solid #cccccc;

        td{
          padding-bottom: 15px !important;
        }
      }

      tbody tr:last-of-type {
        td{
        padding-top: 15px !important;
        &:first-of-type{
          font-weight: bold;
        }
        }
      }

      th, td {
        text-align: left;
        padding: 5px;
        font-size: 13px;
        vertical-align: baseline;
      }
    }
  }

  .rm-btn{
    padding: 4px 6px;
    background-color: #03337A;
    color: #fff;
    font-weight: bold;
  }
}